import React, { useEffect } from "react";
import AOS from "aos";
import YoutubeEmbed from "../UI/YoutubeEmbed/YoutubeEmbed";
import "./Paradise.css";
import "aos/dist/aos.css";
import { HiOutlineDocument } from "react-icons/hi";
import stepParadiseImg from "../../assets/STEP-Whtisundays-Paradise.png";
import paradiseSpecial from "../../assets/Paradise-Special.png";

const Paradise = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="paradise">
      <div className="container">
        <div className="u-title u-text">
          <HiOutlineDocument color="orangered" size={30} />
          <h2>Paradise</h2>
          <p>
            If you are going to live and work somewhere why not in paradise?
          </p>
        </div>
        <div id="paradise-main" className="u-text u-text-dark">
          <div id="paradise-left" data-aos="fade-right">
            <p>
              The Whitsunday Region is on the &quot;Bucket List&quot; of people
              all over the world. Whitehaven Beach is considered by many to be
              the best beach in the world. Airlie Beach and Cannonvale are town
              hubs with almost all the items you could want.
            </p>
            <p>
              If you need to get something that is not available in Airlie then
              it is most likely available in Mackay. Mackay is only a couple of
              hours drive and is the major city in the region. If you want to
              explore the regions, outside of the Islands and the Great Barrier
              Reef, there are literally thousands of world class things to do
              from Bowen to Issac.
            </p>
            <p>... And this is where you will be living!</p>

            <div className="specialBanner">
              <img src={paradiseSpecial} alt="Special" aria-hidden="true" />
            </div>
            <p>
              As a Speedy Global STEP-Whitsundays participant you will get{" "}
              <strong>Special Benefits</strong>. You will get discounts on
              tours, transport to the islands and much more. We will also be
              organising special activities exculively for you. You will live
              and work in paradise and enjoy special pricing that is not
              available to tourists.
            </p>
            <p>... We want you to have fun and not pay full price!</p>
          </div>
          <div id="paradise-right" data-aos="fade-left">
            <img
              src={stepParadiseImg}
              alt="Airlie Beach at Night"
              aria-hidden="true"
            />
          </div>
        </div>
        <hr className="paradise-outer-sep" />
        <div className="paradise-video">
          <h3>Airlie Beach and Whitsunday Islands</h3>
          <YoutubeEmbed embedId="wQAy_1z7AhI" />
        </div>
      </div>
    </section>
  );
};

export default Paradise;
