import React, { useState } from "react";
import "./Question.css";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

const Question = ({ title, answer, note }) => {
  const [showAnswer, setShowAnswer] = useState(false);

  const handleClick = () => {
    setShowAnswer(!showAnswer);
  };

  return (
    <div className="container question">
      <div className="question-title u-text">
        <h3>{title}</h3>
        <button className="question-icons" onClick={handleClick}>
          {showAnswer ? (
            <AiOutlineMinus color="red" size={20} />
          ) : (
            <AiOutlinePlus color="#1f93ff" size={20} />
          )}
        </button>
      </div>
      {showAnswer && (
        <div className="question-answer u-text">
          <p>{answer}</p>
        </div>
      )}
      {showAnswer && (
        <div className="question-note u-text">
          <p>{note}</p>
        </div>
      )}
    </div>
  );
};

export default Question;
