import React, { useEffect } from "react";
import "./Faq.css";
import { questions } from "./data.js";
import Question from "./Question";
import { BsPatchQuestion } from "react-icons/bs";

import AOS from "aos";
import "aos/dist/aos.css";

const Faq = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="faq">
      <div className="container" data-aos="fade-up">
        <div className="u-title u-text">
          <BsPatchQuestion color="orangered" size={30} />
          <h2>FAQs</h2>
          <p>
            Please read the answers to some of the frequently asked questions.
            If you have any others please email us and we will be happy to help
            you.
          </p>
        </div>
        <div className="questions">
          {questions.map((question) => (
            <Question
              key={question.id}
              title={question.title}
              answer={question.answer}
              note={question.note}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faq;
