import React, { useEffect } from "react";
import AOS from "aos";
import YoutubeEmbed from "../UI/YoutubeEmbed/YoutubeEmbed";
import "./Study.css";
import "aos/dist/aos.css";
import { GiGraduateCap } from "react-icons/gi";
import StudyChef from "../../assets/Study-Chef.png";
import StudyHospitality from "../../assets/Study-Hospitality.png";
import StudyEnglish from "../../assets/Study-English.png";

const Study = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="study">
      <div className="container">
        <div className="u-title u-text">
          <GiGraduateCap color="orangered" size={30} />
          <h2>Study</h2>
          <p>
            We have two specific areas within this program that are highly
            professional and supported by internationally recognised employers!
            Study &amp; Work with this exclusive Speedy Global Program.
          </p>
        </div>
        <hr className="study-outer-sep" />
        <div className="study-info u-text u-text-dark">
          <div className="study-course" data-aos="fade-right">
            <img src={StudyChef} alt="Study Chef" aria-hidden="true" />
            <p>
              Certificate IV in Commercial Cookery and
              <br />
              Diploma of Hospitality Management
            </p>
            <p>
              <i>
                CRICOS No.: 092021M and 091046K <br />
                TAFE Program Code: SIT40516 and SIT50416
              </i>
            </p>
            <p>
              If you want to be a highly trained Chef and get two years of
              experience in a world class quality kitchen then we have the
              program for you! This program combines an internationally
              recognised culinary program and a world class Hospitality Diploma.
              After completing this program, you will be able to stay in
              Australia or travel the world with your skills.
            </p>
          </div>
          <div className="study-course" data-aos="fade-left">
            <img
              src={StudyHospitality}
              alt="Study Hospitality"
              aria-hidden="true"
            />
            <p>
              Certificate III in Hospitality and
              <br />
              Diploma of Hospitality Management
            </p>
            <p>
              <i>
                CRICOS No.: 095207K and 092400M <br />
                TAFE Program Code: SIT30616 and SIT50316
              </i>
            </p>
            <p>
              If you want to work at the front of the house in hospitality then
              you should not go past this incredible set of qualifications and
              the experience that comes with it. Baristas who would like to be a
              Café Manager, Waiters who would like to be a Restaurant Manager,
              Receptionists who would like to be a Front Office Manager and
              Bartenders who would like to become a Bar Manager should apply!
            </p>
          </div>
        </div>
        <div className="study-info u-text u-text-dark">
          <div className="study-course" data-aos="fade-right">
            <img src={StudyEnglish} alt="Study English" aria-hidden="true" />
            <p>
              General English and
              <br />
              English for Academic Purposes (EAP)
            </p>
            <p>
              <i>CRICOS No.: 101409 and 101406</i>
            </p>
            <p>
              English is very important in Australia and, indeed, is a very
              common language worldwide! Good English skills will not only help
              you with your study but will quite often allow you to be promoted
              faster in your career. We provide a special English course just
              for our students and best of all you can work while you do it!
            </p>
          </div>

          <div className="study-course" data-aos="fade-left">
            <div className="study-video">
              <YoutubeEmbed embedId="NVGgTyOXSog" />
            </div>
          </div>
        </div>
        <hr className="study-outer-sep" />
      </div>
    </section>
  );
};

export default Study;
