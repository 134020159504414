export const questions = [
	{
		id: 1,
		title: "What about accommodation?",
		answer: "Speedy Global ensures that all of our participants have suitable accommodation and there are a range of choices for you to chose from. We will make sure you can get to your workplace safely and efficiently from your accommodation. There are many options that we can offer you and, of course, you are free to organise your own accommodation by yourself, or with friends, at any time.",
		note: "Student Accommodation normally ranges between 250 and 350 AUD per week.",
	},
	{
		id: 2,
		title: "What about pastoral care?",
		answer: "Speedy Global takes the welfare of our participants very seriously. We provide ongoing assistance to you and ensure you are happy with your study, work, accommodation and play. Further, we only engage with quality employers who also take the welfare of their staff very seriously. So you will be respected, well supported and happy.",
		note: "We are always available if you need help while on our program.",
	},
	{
		id: 3,
		title: "What about English?",
		answer: "If you are not exempt from English requirements, or if you don't already have a qualifying English Score, then we can offer an English program. Best of all our English programs lead directly into our career programs (no IELTS, PET etc. needed) and you can study our English programs in Australia and start work. The amount of training in English you will require will depend on your level and the level required for the course you are doing generally though they will be between 12 weeks and 24 weeks and cost less than 250 AUD per week.",
		note: "A formal English test may be a requirement of Australian Immigration as part of the student visa application for some applicants; i.e. they will have to have an IELTS, PET etc. and will not be able to do the English courses. We will inform you if this applies to you. So don't do a test till you ask us!",
	},
	{
		id: 4,
		title: "Can I work while on the Student Visa?",
		answer: "All of our participants can work in Australia legally and at the same rate as an Australian would get for the same work. They also have the same working conditions as Australians. Speedy Global will assist all participants to access work during their program, if they wish. As of 1 July 2023 student visa holders can work up to 48 hours a fortnight in any sector of the economy while school/college is in session. There is no limit on the number of hours an international student can work during recognised school vacations. Unlike America, Singapore and other countries where students are not legally allowed to work in Australia they can work to offset the cost of tuition and living.",
		note: "In short: Yes you can work, with some restrictions, at full normal Australian pay and conditions. BUT your study must be your first priority.",
	},
	{
		id: 5,
		title: "What about my future after the two year program?",
		answer: "With the Speedy STEP program, you have a world of possibilities ahead of you. All of our qualifications can lead to remaining in Australia or returning to Australia later in your career. All the qualifications are in fields that currently can lead to Permanent Residency in Australia. After the two-year program Speedy Global will guarantee to place all hospitality and culinary professionals on an international cruise line, if they wish, and after working around the world they can still return to Australia, if they like. Speedy Global will help you come back, guaranteed!",
		note: "There are a huge number of options open to you after you complete the Speedy STEP program. Your future success is one Speedy STEP.",
	},
	{
		id: 6,
		title: "How much will it cost?",
		answer: "The cost for our programs will vary from approximately 20,000 AUD to 33,000 AUD over 2 years depending upon the course you choose. You will not have to pay it all upfront. You will normally need to pay a deposit, of up to 6 months study, for your program to the provider to get your Certificate of Enrollement (CoE). On top of this you will need to prepare yourself, prepare your documents, meet visa requirement, get Overseas Student Health Cover (OSHC) and fly to Australia. You should bring enough money with you to cover the first month in Australia. The advantage of our program is you will be interviewed for a job in the first week you are in Australia: so your income will start very quickly and from then you should make enough money to pay for your study, living, supporting your family and having fun!",
		note: "Do not worry! We will help you through the process and make sure you have a full budget with no surprises! Talk to us - we are sure you will be fine",
	},
	{
		id: 7,
		title: "Do I need Health Cover?",
		answer: "Overseas Student Health Cover (OSHC) is required for all students who come to Australia. Australia has an excellent health system but it can be expensive. For this reason health cover is mandatory. We will help you with this and give you all the details. You can purchase it through us (we are generally slightly lower cost than other options) but if you want you can purchase it through your college or on your own.",
		note: "Our health cover through us is quick, easy and usually the best value but you can get your own, if you like.",
	},
	{
		id: 8,
		title: "How do I apply?",
		answer: "Please fill in our 'subscribe' form on this page or contact your nearest Speedy Global Office or partner: https://Speedy.Global/contact",
		note: "Once you subscribe our nearest office will send you more details and answer your questions.",
	},
	{
		id: 9,
		title: "Can family come to Australia also?",
		answer: "The short answer is you can bring your dependants with you on a Student Visa. However the full answer is much longer and more complicated.",
		note: "Please contact Speedy Global, tell us your situation and we will give you better advice.",
	},
	{
		id: 10,
		title: "How long does the process take?",
		answer: "Both the Colleges and Speedy Global process you very quickly. The unknown is how long the Australian Immigration Department will take to process your visa. We have no control over this at all.",
		note: "See https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-processing-times/global-processing-times#",
	},
	{
		id: 11,
		title: "Will a MARA Migration Agent do my visa?",
		answer: "YES - Speedy Global works with Milestone Migration and all of our student applications are reviewed and submitted by them. So we know the submission is accurate and should meet all the criteria.",
		note: "See our Migration Agents' https://milestonemigration.com",
	},
];
