import React, { useEffect } from "react";
import AOS from "aos";
import YoutubeEmbed from "../UI/YoutubeEmbed/YoutubeEmbed";
import "./Tafe.css";
import "aos/dist/aos.css";
import { BsBook } from "react-icons/bs";
import stepTafeTAFEImg from "../../assets/STEP-Colleges-TAFE.png";

const Tafe = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="tafe">
      <div className="container">
        <div className="u-title u-text">
          <BsBook color="orangered" size={30} />
          <h2>Tafe</h2>
          <p>
            To ensure STEP-Whitsunday particpants have the best possible future
            we only work with TAFE Whitsundays Campus who provide the best
            possible standard of fully accredited and recognised training in the
            region.
          </p>
        </div>
        <hr className="tafe-outer-sep" />
        <div id="tafe-img-right" className="u-text u-text-light">
          <div id="tafe-text" data-aos="fade-right">
            <div id="tafe">
              <p>
                TAFE Queensland is Queensland's largest and most experienced
                training provider. They deliver practical, industry-relevant
                training across a range of industries from entry-level
                certificates to bachelor degrees, at more than 50 locations in
                Queensland.
              </p>
              <p>
                TAFE Qld Whitsundays campus is located in Cannonvale, the
                business precinct of the Whitsundays region. Located just three
                kilometres from the popular tourist destination of Airlie Beach,
                you'll have easy access to many attractions in the area
                including beautiful beaches, lagoons, foreshore, marina,
                entertainment and nightlife.
              </p>
              <p>
                Our Whitsundays campus specialises in hospitality with a full
                service training restaurant and cafe operated and staffed by
                hospitality and cookery students. As well as having a full range
                of hospitality courses including cookery, kitchen operations,
                and responsible service of alcohol (RSA), the campus also offers
                courses in early childhood education and care.
              </p>
            </div>
          </div>
          <div id="tafe-image" data-aos="fade-text">
            <img
              src={stepTafeTAFEImg}
              alt="TAFE Queensland"
              aria-hidden="true"
            />
          </div>
        </div>
        <hr className="tafe-inner-sep" />
        <div className="tafe-video">
          <h3>TAFE Video</h3>
          <YoutubeEmbed embedId="zB3s9a0Zl_A" />
        </div>
        <hr className="tafe-outer-sep" />
      </div>
    </section>
  );
};

export default Tafe;
