import React from "react";
import "./Footer.css";

import speedyGlobalLogo from "../../assets/speedy-logo-white.png";

import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaFax,
  FaEnvelope,
  FaGlobe,
} from "react-icons/fa";

const Footer = () => {
  return (
    <section id="footer">
      <div className="container u-text-light">
        <div id="footer-main">
          <div id="footer-left">
            <img
              src={speedyGlobalLogo}
              alt="Speedy Global Logo White"
              aria-hidden="true"
            />
            <p className="u-text-small">&copy; Copyright 2022. Speedy.Global</p>
            <hr />
          </div>
          <div id="footer-right">
            <div id="footer-contact" className="u-text u-text-small">
              <div className="footer-row">
                <div className="footer-row-left">
                  <FaMapMarkerAlt /> &nbsp;Address:
                </div>
                <div className="footer-row-right">
                  27 FOCH ROAD
                  <br />
                  #05-01 HOA NAM BUILDING
                  <br />
                  SINGAPORE 209264
                </div>
              </div>
              <div className="footer-row">
                <div className="footer-row-left">
                  <FaPhoneAlt /> &nbsp; Phone:
                </div>
                <div className="footer-row-right">+65 68 160 495</div>
              </div>
              <div className="footer-row">
                <div className="footer-row-left">
                  <FaFax /> &nbsp; Fax:
                </div>
                <div className="footer-row-right">+65 68 160 497</div>
              </div>
              <div className="footer-row">
                <div className="footer-row-left">
                  <FaEnvelope /> &nbsp; Email:
                </div>
                <div className="footer-row-right">info@speedy.global</div>
              </div>
              <div className="footer-row">
                <div className="footer-row-left">
                  <FaGlobe /> &nbsp; Website:
                </div>
                <div className="footer-row-right">www.speedy.global</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
