import React, { useEffect } from "react";
import CoralSea from "../../assets/whitsunday-employer-coralsea.png";
import FishDVine from "../../assets/whitsunday-employer-fishdvine.png";
import FreedomShores from "../../assets/whitsunday-employer-freedom.png";
import HamiltonIsland from "../../assets/whitsunday-employer-hamilton.png";
import InterContinental from "../../assets/whitsunday-employer-intercontinental.png";
import LaMarina from "../../assets/whitsunday-employer-lamarina.png";
import Northerlies from "../../assets/whitsunday-employer-northerlies.png";
import Paradiso from "../../assets/whitsunday-employer-paradiso.png";
import "./Employers.css";
import "../UI/Button/Button.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { MdOutlineAttachMoney } from "react-icons/md";

const Employers = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="employers">
      <div className="container u-text u-text-light" data-aos="fade-up">
        <div className="u-title">
          <MdOutlineAttachMoney color="orangered" size={30} />
          <h2>World Class Employers</h2>
          <p>
            Our program is supported by some of the most recognized and
            respected hospitality businesses in the world! You will be fully
            paid for the work you do while you are studying with us.
          </p>
        </div>
        <div className="employers-content">
          <div className="employer-details">
            <h3>Coral Sea Marina Resort</h3>
            <a
              href="https://www.coralseamarina.com/"
              target="_blank"
              rel="noreferrer"
            >
              www.coralseamarina.com
            </a>
          </div>
          <div className="employer-logo">
            <img src={CoralSea} alt="Coral Sea Logo" aria-hidden="true" />
          </div>
        </div>

        <div className="employers-content">
          <div className="employer-details">
            <h3>Fish d'Vine</h3>
            <a
              href="https://www.fishdvine.com.au/"
              target="_blank"
              rel="noreferrer"
            >
              www.fishdvine.com.au
            </a>
          </div>
          <div className="employer-logo">
            <img src={FishDVine} alt="Fish d Vine Logo" aria-hidden="true" />
          </div>
        </div>

        <div className="employers-content">
          <div className="employer-details">
            <h3>Freedom Shores</h3>
            <a
              href="https://www.freedomshores.com.au/"
              target="_blank"
              rel="noreferrer"
            >
              www.freedomshores.com.au
            </a>
          </div>
          <div className="employer-logo">
            <img
              src={FreedomShores}
              alt="Freedom Shores Logo"
              aria-hidden="true"
            />
          </div>
        </div>

        <div className="employers-content">
          <div className="employer-details">
            <h3>Hamilton Island</h3>
            <a
              href="https://www.hamiltonisland.com.au/"
              target="_blank"
              rel="noreferrer"
            >
              www.hamiltonisland.com.au
            </a>
          </div>
          <div className="employer-logo">
            <img
              src={HamiltonIsland}
              alt="Hamilton Island Logo"
              aria-hidden="true"
            />
          </div>
        </div>

        <div className="employers-content">
          <div className="employer-details">
            <h3>Hayman Island Intercontinental</h3>
            <a
              href="https://haymanisland.intercontinental.com/"
              target="_blank"
              rel="noreferrer"
            >
              haymanisland.intercontinental.com
            </a>
          </div>
          <div className="employer-logo">
            <img
              src={InterContinental}
              alt="Haymanisland Intercontinental Logo"
              aria-hidden="true"
            />
          </div>
        </div>

        <div className="employers-content">
          <div className="employer-details">
            <h3>La Marina</h3>
            <a href="https://lamarina.com.au/" target="_blank" rel="noreferrer">
              lamarina.com.au
            </a>
          </div>
          <div className="employer-logo">
            <img src={LaMarina} alt="La Marina Logo" aria-hidden="true" />
          </div>
        </div>

        <div className="employers-content">
          <div className="employer-details">
            <h3>Northerlies</h3>
            <a
              href="https://www.northerlies.com.au/"
              target="_blank"
              rel="noreferrer"
            >
              www.northerlies.com.au
            </a>
          </div>
          <div className="employer-logo">
            <img src={Northerlies} alt="Northerlies Logo" aria-hidden="true" />
          </div>
        </div>

        <div className="employers-content">
          <div className="employer-details">
            <h3>Paradiso</h3>
            <a
              href="https://www.facebook.com/paradisowhitsundays/"
              target="_blank"
              rel="noreferrer"
            >
              www.facebook.com/paradisowhitsundays
            </a>
          </div>
          <div className="employer-logo">
            <img src={Paradiso} alt="Paradiso Logo" aria-hidden="true" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Employers;
