import "./App.css";
import ScrollToTop from "./components/scrolltotop/ScrollToTop";

import {
  Navbar,
  Header,
  Study,
  Tafe,
  Employers,
  Paradise,
  Faq,
  Subscribe,
  Footer,
} from "./components";

function App() {
  return (
    <>
      <header className="header-bg">
        <Navbar />
        <Header />
      </header>
      <ScrollToTop />
      <Study data-aos="fade-up" />
      <Tafe />
      <Employers />
      <Paradise />
      <Faq />
      <Subscribe />
      <Footer />
    </>
  );
}

export default App;
