import Button from "../UI/Button/Button";
import { BsMouse } from "react-icons/bs";

import stepHeaderImg from "../../assets/STEP-Whitsundays-General.png";
import employerLogos from "../../assets/step_whitsundays_employer_logos.png";

import "./Header.css";
import "../UI/Button/Button.css";
import "aos/dist/aos.css";

const Header = () => {
  return (
    <section id="header">
      <div className="container">
        <div id="header-main" className="u-text u-text-light">
          <div id="header-left">
            <h1>
              <div id="header-subtitle">
                <span>S</span>tudy <span>T</span>AFE <span>E</span>
                mployment <span>P</span>aradise
              </div>
            </h1>
            <h2>
              If you want to be a Chef or Professional Hospitality Worker then
              why not:
            </h2>
            <ul>
              <li>Learn your profession from a world class institution? </li>
              <li>Be fully paid by an internationally recognised employer?</li>
              <li>Live in one of the most beautiful places on earth?</li>
            </ul>

            <p>
              You will study for two years in one of the best international
              tourist destinations in Australia, or the world for that matter.
              You will earn the money you need to pay for your study, live in
              paradise, suport your family and have a lot of fun!
            </p>
            <h2>
              <i>
                <span>&quot;</span>Get fully paid to live and learn in paradise!
                <span>&quot;</span>
              </i>
            </h2>
            <div id="header-cta">
              <Button
                text={"Get Started"}
                btnClass={"btn-dark"}
                href={"#subscribe"}
              />
              <Button
                text={"Employers"}
                btnClass={"btn-light"}
                href={"#employers"}
              />
            </div>
          </div>
          <div id="header-right">
            <img
              src={stepHeaderImg}
              alt="Study Training Employment Play - General Image"
              aria-hidden="true"
            />
          </div>
        </div>
        <div className="employer-logos">
          <img
            src={employerLogos}
            alt="Employers supporting STEP-Whitsunday Program"
            aria-hidden="true"
          />
        </div>
        <div id="floating-icon">
          <a href="#features">
            <BsMouse color="#fff" size={25} id="mouse" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Header;
